import React from 'react';
import { RichText } from 'prismic-reactjs';

/**
 * Site footer component
 */
const Footer = ({ text }) => (
  <footer>

    <div className="containter">
      {RichText.render(text)}
    </div>

  </footer>
);

export default Footer;
