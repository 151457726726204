import React from 'react';
import { RichText } from 'prismic-reactjs';

/**
 * Homepage header component
 */
const Header = ({ data }) => (

<section id="hero" className="parent-element d-flex flex-column justify-content-center align-items-center">
    <div className="child-element left-child" data-aos="fade-up" style={{ backgroundImage: `url(${data.image.url})` }}>
      <div className="text-md">
        <div className="left-textbox">
          {RichText.render(data.headline_left)}
        </div>
      </div>
    </div>
    <div className="child-element right-child" data-aos="fade-up">
      <div className="text-md">
        <h1>{RichText.render(data.headline)}</h1> 
      </div>
    </div>
</section>  

);

export default Header;
